import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDuqGdjUa1SlGprfgYPCT98h4Ado7uNlQ8",
    authDomain: "dentalwex-442ea.firebaseapp.com",
    projectId: "dentalwex-442ea",
    storageBucket: "dentalwex-442ea.appspot.com",
    messagingSenderId: "624138710924",
    appId: "1:624138710924:web:aa45751f380bcbfb00f0a9"
  };

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firedb = firebase.firestore();