import React, { Component, createContext } from "react";
import { auth } from "../firebase";
import { navigate } from "@reach/router"

export const UserContext = createContext({ user: null });
class UserProvider extends Component {
  state = {
    user: null
  };

  componentDidMount = () => {
    auth.onAuthStateChanged(userAuth => {
       
        this.setState({ user: userAuth});
        if(userAuth == null){
          navigate('/')
        }
        
    });
  };
  render() {
    return (
      <UserContext.Provider value={this.state.user}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
export default UserProvider;