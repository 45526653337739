import React, { useContext, useState, useEffect } from "react";
import { Router } from "@reach/router";
import Home from ".././Home";
import Login from ".././Login";
import Dashboard from ".././Dashboard";
import UserProvider from "../../providers/UserProvider";
import { UserContext } from "../../providers/UserProvider";


function Application() {
  const user = useContext(UserContext);
  const [opacity, setOpacity] = useState(1);
  const [visibility, setVisibility] = useState('visible');
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpacity(0)
      setVisibility('hidden')
    }, 800);
    return () => clearTimeout(timer);
  }, []);
  
  return (
    <div>
        { user ?
        <Router>
            <Home user={user} path="/" />
            <Dashboard user={user} path="dashboard" />
          </Router>
      :
        
          <Router>
            <Login path="/" />
          </Router>
        }
        
          <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            position: "fixed",
            top: 0,
            opacity: opacity,
            visibility: visibility,
            backgroundColor: "white",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <i class="fas fa-spinner fa-spin fa-3x"></i>
        </div>
      
    </div>
  );
}
export default Application;