import React, {useState} from "react";
import { auth } from "../../firebase";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [btnLoading, setBtnLoading] = useState(0)
    const signInWithEmailAndPasswordHandler = 
            (event,email, password) => {
                event.preventDefault();
                setBtnLoading(1);
                auth.signInWithEmailAndPassword(email, password).catch(error => {
                    setError("Error signing in with password and email!");
                    setBtnLoading(0);
                      console.error("Error signing in with password and email", error);
                    });
    };

      const onChangeHandler = (event) => {
          const {name, value} = event.currentTarget;

          if(name === 'userEmail') {
              setEmail(value);
          }
          else if(name === 'userPassword'){
            setPassword(value);
          }
      };

  return (
   <div className="container">
     <div className="row">
       <div className="col-md-5 mx-auto">
       <div className="mt-5 loginFormWrapper">
      <img src={process.env.PUBLIC_URL +"/img/lplogo.png"} alt="site-logo" className="mb-5 img-fluid" />
      <h1 className="h3 login-text text-center">Work Experience Portal</h1>
      <p class="text-muted mt-4 text-center">Please use the email address which you recieved your invitation link on and the event password supplied in that email</p>
      <div className="px-5 mt-4">
        {error !== null && <div className = "py-4 bg-red-600 w-full text-danger text-center mb-3">{error}</div>}
        <form className="loginForm">
          <div classname="form-group">
          <label htmlFor="userEmail" className="block">
            Email:
          </label>
          <input
            type="email"
            className="my-1 p-1 w-full form-control"
            name="userEmail"
            value = {email}
            placeholder="E.g: youremail@gmail.com"
            id="userEmail"
            onChange = {(event) => onChangeHandler(event)}
          />
          </div>
          <div className="form-group">
          <label htmlFor="userPassword" className="block">
            Password:
          </label>
          <input
            type="password"
            className="mt-1 mb-3 p-1 w-full form-control"
            name="userPassword"
            value = {password}
            placeholder="Your Password"
            id="userPassword"
            onChange = {(event) => onChangeHandler(event)}
          />
          </div>
          <button className="{btnLoading ? 'disabled' : ''} btn text-white bg1" onClick = {(event) => {signInWithEmailAndPasswordHandler(event, email, password)}}>
            {btnLoading ? 'loading...' : 'Login'}
          </button>
          <p className="text-muted text-center mt-5">Event Password: DENT2021@</p>
        </form>
      </div>
    </div>
       </div>
     </div>
   </div>
  );
};
export default Login;